import type { AwsOrganizationUsageResponse } from '@console/services/api.models';
import type { CustomerId, AwsOrganizationId } from '@shared/services/namedIds';

import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';

export type AwsState = {
  organizationUsages: Map<AwsOrganizationId, AwsOrganizationUsageResponse>;
};

export const useAwsStore = defineStore('aws', () => {
  const vuexStore = useVuexStore();

  // Define common/general properties here
  const selectedCustomerId = computed<CustomerId | undefined>(() => vuexStore.getters['customer/selectedCompanyId']);
  const selectedOrganizationId = computed<AwsOrganizationId | undefined>(() => vuexStore.getters['aws/selectedOrganizationId']);
  const isMultiOrgSummarySelected = computed(() => selectedOrganizationId.value === 'MULTI_ORG_SUMMARY');
  const isNewOrganization = computed(() => selectedOrganizationId.value === 'NEW_ORGANIZATION_PLACEHOLDER');

  return {
    selectedOrganizationId,
    isMultiOrgSummarySelected,
    isNewOrganization,

    ...defineUsageProperties(),
  }

  // Define standalone/module properties here

  function defineUsageProperties() {
    const usageByOrganizationId = ref(new Map<AwsOrganizationId, AwsOrganizationUsageResponse>());
    // If the customer changes, clear the usage
    watch(selectedCustomerId, () => usageByOrganizationId.value.clear());

    const usage = computed<AwsOrganizationUsageResponse | undefined>(() => {
      if (!selectedOrganizationId.value || isMultiOrgSummarySelected.value || isNewOrganization.value) return;

      return usageByOrganizationId.value.get(selectedOrganizationId.value);
    });

    async function loadOrganizationUsage() {
      if (!selectedOrganizationId.value || isMultiOrgSummarySelected.value || isNewOrganization.value) return;
      if (usage.value) return;

      const usageResponse = await customerService.getAwsServiceUsage(selectedOrganizationId.value);
      usageByOrganizationId.value.set(selectedOrganizationId.value, usageResponse?.data);
    }

    return { usage, loadOrganizationUsage}
  }
});
