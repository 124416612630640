import _ from 'lodash';

import * as mask from '@console/lib/mask';
import { getManagementCustomRole, getSavingsAnalysisCustomRole } from '@gcp/services/accessValidation';
import {
  activateBillingAccount,
  getBillingAccountComputeEngineSettings,
  getBillingAccountSettings,
  listBillingAccounts,
  provisionBillingAccount,
  getSavingsDashboard,
  getCloudSQLSavingsDashboard,
  getCommitmentsDashboard,
  getServiceUsage,
} from '@gcp/services/billingAccountService';
import { addServiceAccount, getServiceAccount } from '@gcp/services/serviceAccount';
import { BillingOffering } from '@gcp/utilities/constants';

const state = {
  billingConfiguration: null,
  billingAccounts: null,
  // Compute Engine SettingSummaryResponse by BillingAccount
  billingAccountSettings: {},
  // BillingAccountSettingsResponse by (BillingAccount,BillingOffering)
  settingsByBillingAccountAndBillingOffering: {},
  serviceAccount: null,
  savingsAnalysisRole: null,
  managementRole: null,
  savingsDashboard: null,
  cloudSqlSavingsDashboard: null,
  commitmentsDashboard: null,
  serviceUsage: null,
};

const maskBillingAccounts = billingAccounts => {
  return _.map(billingAccounts, billingAccount => {
    return _.assign({}, billingAccount, {
      display_name: mask.generateGcpBillingAccountDisplayName(billingAccount.id),
      billing_account_name: mask.generateGcpBillingAccountName(),
      billing_account_id: mask.generateGcpBillingAccountName(),
    });
  });
};

const maskComputeEngineSavingsDashboard = savings => {
  const maskedSavings = { ...savings };
  return maskedSavings;
};

const maskComputeEngineCommitmentsDashboard = commitmentsDashboard => {
  const maskedCommitmentsDashboard = { ...commitmentsDashboard };
  return maskedCommitmentsDashboard;
};

const mutations = {
  RESET(state) {
    state.billingConfiguration = null;
    state.billingAccounts = null;
    state.billingAccountSettings = {};
    state.settingsByBillingAccountAndBillingOffering = {};
    state.serviceAccount = null;
    state.savingsAnalysisRole = null;
    state.managementRole = null;
    state.savingsDashboard = null;
    state.cloudSqlSavingsDashboard = null;
    state.commitmentsDashboard = null;
    state.serviceUsage = null;
  },
  CLEAR_BILLING_CONFIGURATION(state) {
    state.billingConfiguration = null;
  },
  SET_BILLING_CONFIGURATION(state, billingConfiguration) {
    state.billingConfiguration = billingConfiguration;
  },
  SET_BILLING_ACCOUNT(state, billingAccount) {
    state.billingAccounts[billingAccount.id] = billingAccount;
  },
  SET_BILLING_ACCOUNT_SETTINGS(state, { billingAccountId, billingAccountSettings }) {
    state.billingAccountSettings[billingAccountId] = billingAccountSettings;
  },
  SET_SETTINGS_BY_BILLING_OFFERING_AND_BILLING_ACCOUNT(state, { billingAccountId, billingOffering, settings }) {
    state.settingsByBillingAccountAndBillingOffering[(billingAccountId, billingOffering)] = settings;
  },
  SET_BILLING_ACCOUNTS(state, billingAccounts) {
    state.billingAccounts = _.keyBy(billingAccounts, 'id');
  },
  SET_SERVICE_ACCOUNT(state, serviceAccount) {
    state.serviceAccount = serviceAccount;
  },
  SET_SAVINGS_ANALYSIS_ROLE(state, savingsAnalysisRole) {
    state.savingsAnalysisRole = savingsAnalysisRole;
  },
  SET_MANAGEMENT_ROLE(state, managementRole) {
    state.managementRole = managementRole;
  },
  SET_SAVINGS_DASHBOARD(state, savingsDashboard) {
    state.savingsDashboard = savingsDashboard;
  },
  SET_CLOUD_SQL_SAVINGS_DASHBOARD(state, cloudSqlSavingsDashboard) {
    state.cloudSqlSavingsDashboard = cloudSqlSavingsDashboard;
  },
  SET_COMMITMENTS_DASHBOARD(state, commitmentsDashboard) {
    state.commitmentsDashboard = commitmentsDashboard;
  },
  SET_SERVICE_USAGE(state, serviceUsage) {
    state.serviceUsage = serviceUsage;
  },
};

const getters = {
  selectedBillingAccountId(state, getters, rootState) {
    return _.get(rootState, ['nav', 'context', 'id']);
  },
  isBillingAccountOnboarded: state => id => {
    const billingAccount = state.billingAccounts[id];
    return ['Active'].includes(billingAccount?.status);
  },
  getBillingAccountById: state => id => {
    return state.billingAccounts[id];
  },
  getBillingAccountSettingsById: state => id => {
    return state.billingAccountSettings[id];
  },
  getSettingsByBillingAccountAndBillingOffering: state => (billingAccountId, billingOffering) => {
    return state.settingsByBillingAccountAndBillingOffering[(billingAccountId, billingOffering)];
  },
  billingAccounts(state) {
    return _.values(state.billingAccounts);
  },
  billingAccountId(state) {
    return state.billingConfiguration?.billingAccountId;
  },
  detailedUsageExportProjectId(state) {
    return state.billingConfiguration?.detailedUsageExportDatasetId.split('.')[0];
  },
  detailedUsageExportDatasetId(state) {
    return state.billingConfiguration?.detailedUsageExportDatasetId.split('.')[1];
  },
  pricingExportProjectId(state) {
    return state.billingConfiguration?.pricingExportDatasetId.split('.')[0];
  },
  pricingExportDatasetId(state) {
    return state.billingConfiguration?.pricingExportDatasetId.split('.')[1];
  },
  organizationId(state) {
    return state.billingConfiguration?.organizationId;
  },
  savingsTimeframes(state) {
    return state.savingsDashboard?.available_timeframes || [];
  },
  cloudSqlSavingsTimeframes(state) {
    return state.cloudSqlSavingsDashboard?.available_timeframes || [];
  },
  serviceUsage(state) {
    return state.serviceUsage;
  },
};

const actions = {
  async reload(context) {
    context.commit('RESET');
    await context.dispatch('loadAllBillingAccounts');
  },
  async startOnboarding(context, billingConfiguration) {
    const serviceAccount = await addServiceAccount();
    context.commit('SET_SERVICE_ACCOUNT', serviceAccount);
    context.commit('SET_BILLING_CONFIGURATION', billingConfiguration);
  },
  async editBillingConfiguration(context) {
    const billingConfiguration = { ...context.state.billingConfiguration, editing: true };
    context.commit('SET_BILLING_CONFIGURATION', billingConfiguration);
  },
  async loadCustomerServiceAccount(context) {
    const serviceAccount = await getServiceAccount();
    context.commit('SET_SERVICE_ACCOUNT', serviceAccount);
  },
  async loadSavingsAnalysisRole(context) {
    const savingsAnalysisRole = await getSavingsAnalysisCustomRole();
    context.commit('SET_SAVINGS_ANALYSIS_ROLE', savingsAnalysisRole);
  },
  async loadManagementRole(context) {
    const managementRole = await getManagementCustomRole();
    context.commit('SET_MANAGEMENT_ROLE', managementRole);
  },
  async addBillingAccount(context) {
    const billingAccountId = context.getters.billingAccountId;
    const billingExportProjectId = context.getters.detailedUsageExportProjectId;
    const billingExportSourceDatasetId = context.getters.detailedUsageExportDatasetId;
    const pricingExportProjectId = context.getters.pricingExportProjectId;
    const pricingExportSourceDatasetId = context.getters.pricingExportDatasetId;
    const organizationId = context.getters.organizationId;

    const billingAccount = await provisionBillingAccount({
      billing_account_id: billingAccountId,
      billing_export_detail_project_id: billingExportProjectId,
      billing_export_detail_dataset_id: billingExportSourceDatasetId,
      pricing_export_project_id: pricingExportProjectId,
      pricing_export_dataset_id: pricingExportSourceDatasetId,
      organization_id: organizationId,
    });

    await context.commit('SET_BILLING_ACCOUNT', billingAccount);
    await context.commit('CLEAR_BILLING_CONFIGURATION');
    await context.dispatch('nav/switch', { cloud: 'gcp', id: billingAccount.id }, { root: true });
  },
  async activateBillingAccount(context, { billingAccountId }) {
    const billingAccount = await activateBillingAccount(billingAccountId);
    await context.commit('SET_BILLING_ACCOUNT', billingAccount);
  },
  async loadAllBillingAccounts(context) {
    if (context.state.billingAccounts !== null) {
      return;
    }

    const billingAccounts = await listBillingAccounts();
    const isDemo = context.rootGetters['customer/isDemo'];

    if (isDemo) {
      const activeBillingAccounts = _.filter(billingAccounts, { status: 'Active' });
      context.commit('SET_BILLING_ACCOUNTS', maskBillingAccounts(activeBillingAccounts));
    } else {
      context.commit('SET_BILLING_ACCOUNTS', billingAccounts);
    }
  },
  async loadSelectedBillingAccount({ commit, rootGetters }) {
    const context = rootGetters['nav/context'];
    const isGcpContext = rootGetters['nav/isGcpContext'];

    if (!isGcpContext || context.id === 'NEW_BILLING_ACCOUNT_PLACEHOLDER') {
      return;
    }

    const settings = await getBillingAccountComputeEngineSettings(context.id);
    commit('SET_BILLING_ACCOUNT_SETTINGS', { billingAccountId: context.id, billingAccountSettings: settings });

    const cloudSqlSettings = await getBillingAccountSettings(context.id, BillingOffering.GoogleCloudCloudSQL);
    commit('SET_SETTINGS_BY_BILLING_OFFERING_AND_BILLING_ACCOUNT', {
      billingAccountId: context.id,
      billingOffering: BillingOffering.GoogleCloudCloudSQL,
      settings: cloudSqlSettings,
    });
  },
  async loadSavingsDashboard({ commit, rootGetters }, { billingAccountId, timeframe }) {
    const dashboard = await getSavingsDashboard(billingAccountId, timeframe);

    const isDemo = rootGetters['customer/isDemo'];
    if (isDemo) {
      commit('SET_SAVINGS_DASHBOARD', maskComputeEngineSavingsDashboard(dashboard));
    } else {
      commit('SET_SAVINGS_DASHBOARD', dashboard);
    }
  },
  async loadCloudSQLSavingsDashboard({ commit, rootGetters }, { billingAccountId, timeframe }) {
    const dashboard = await getCloudSQLSavingsDashboard(billingAccountId, timeframe);
    commit('SET_CLOUD_SQL_SAVINGS_DASHBOARD', dashboard);
  },
  async loadCommitmentsDashboard({ commit, rootGetters }, { billingAccountId, timeframe }) {
    const dashboard = await getCommitmentsDashboard(billingAccountId, timeframe);

    const isDemo = rootGetters['customer/isDemo'];
    if (isDemo) {
      commit('SET_COMMITMENTS_DASHBOARD', maskComputeEngineCommitmentsDashboard(dashboard));
    } else {
      commit('SET_COMMITMENTS_DASHBOARD', dashboard);
    }
  },
  async loadServiceUsage({ commit, rootGetters }, { billingAccountId, timeframe }) {
    const context = rootGetters['nav/context'];
    const usage = await getServiceUsage(context.id, timeframe);
    commit('SET_SERVICE_USAGE', usage);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
