<script setup lang="ts">
import type { Placement } from '@floating-ui/vue';
import type { HTMLAttributes } from 'vue';

import { arrow, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import { computed, ref, useSlots } from 'vue';

export type TooltipSize = 'md' | 'lg';

type Props = {
  placement?: Placement;
  tooltipClass?: string;
  size?: TooltipSize;
  overflowScrollHeight?: string;
};

const props = withDefaults(defineProps<Props>(), {
  placement: 'top',
  tooltipClass: '',
  size: 'md',
  overflowScrollHeight: 'auto',
});

const slots = useSlots();

const target = ref<HTMLElement | null>(null);
const tooltip = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);

const show = ref(false);
const CLOSE_DELAY_MS = 50;

const { floatingStyles, middlewareData, placement } = useFloating(target, tooltip, {
  placement: props.placement,
  middleware: [offset(10), flip(), shift({ padding: 5 }), arrow({ element: floatingArrow })],
  whileElementsMounted: autoUpdate,
});

// When the tooltip is placed on a side, the arrow is placed on the opposite side and only the corresponding sides of
// the arrow should have a visible border.
// E.g. when the tooltip is placed on the top, the arrow is placed on the bottom and the right and bottom sides of the
// arrow have a border (after being rotated 45 degrees clockwise)
const arrowStylePerSide = {
  top: { position: 'bottom', borderWidth: '0 1px 1px 0' },
  right: { position: 'left', borderWidth: '0 0 1px 1px' },
  bottom: { position: 'top', borderWidth: '1px 0 0 1px' },
  left: { position: 'right', borderWidth: '1px 1px 0 0' },
};
const arrowStyleInfo = computed(
  () => arrowStylePerSide[placement.value.split('-')[0] as keyof typeof arrowStylePerSide]
);
const arrowPosition = computed(() => middlewareData.value.arrow);

const arrowStyle = computed<HTMLAttributes['style']>(() => ({
  position: 'absolute',
  left: arrowPosition.value?.x != null ? `${arrowPosition.value.x}px` : '',
  top: arrowPosition.value?.y != null ? `${arrowPosition.value.y}px` : '',
  [arrowStyleInfo.value.position]: '-6px',
  borderWidth: arrowStyleInfo.value.borderWidth,
}));

const closeTooltipTimer = ref<number | null>(null);

function handleMouseOver() {
  // If the tooltip's body isn't defined, do nothing
  if (!slots.tooltip) return;

  // If the user hovered over the target or the tooltip, clear out the close timer (if set) and show the tooltip
  clearCloseTimeout();
  show.value = true;
}

function handleMouseLeave() {
  // If the user left the target or the tooltip, clear out the close timer (if already set) and start a new timer to
  // close the tooltip
  if (closeTooltipTimer.value) clearCloseTimeout();

  closeTooltipTimer.value = (setTimeout(() => {
    show.value = false;
    closeTooltipTimer.value = null;
  }, CLOSE_DELAY_MS) as unknown) as number;
}

function clearCloseTimeout() {
  if (!closeTooltipTimer.value) return;

  clearTimeout(closeTooltipTimer.value);
  closeTooltipTimer.value = null;
}
</script>

<script lang="ts">
// Create a stub of the tooltip to render both the default and tooltip slots, otherwise it will just render the default slot
export const TooltipStub = {
  template: `
    <div id="content"><slot /></div>
    <!-- Only render the tooltip slot if it exists, to reproduce the behavior of the Tooltip component -->
    <div id="tooltip" v-if="$slots.tooltip"><slot name="tooltip" /></div>
  `,
};
</script>

<template>
  <span ref="target" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
    <slot></slot>

    <Teleport to="body">
      <div
        v-if="show"
        ref="tooltip"
        class="floatingTooltip"
        :style="floatingStyles"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
      >
        <div
          class="floatingTooltipBody"
          :class="[props.size, props.tooltipClass]"
          data-test-id="tooltip-body"
          :style="{
            overflowY: 'auto',
            maxHeight: props.overflowScrollHeight,
          }"
        >
          <slot name="tooltip"></slot>
        </div>
        <div ref="floatingArrow" class="floatingArrow" :style="arrowStyle"></div>
      </div>
    </Teleport>
  </span>
</template>

<style lang="scss" scoped>
@use '@shared/scss/colors.scss';

.floatingTooltip {
  z-index: 1060;
}

.floatingTooltipBody {
  padding: 0.5rem 0.75rem;
  font-size: 0.83125rem;
  color: colors.$gray-900;
  background-color: colors.$gray-50;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &.md {
    max-width: 276px;
  }

  &.lg {
    max-width: 500px;
  }
}

.floatingArrow {
  position: absolute;
  width: 12px;
  height: 12px;
  background: colors.$gray-50;

  // Display the border on the top part of the arrow
  border: solid 0 rgb(0, 0, 0, 0.2);

  // Rotate the box by 45 degrees to make it look like an arrow (half of the diamond is visible, making a triangle)
  transform: rotate(45deg);
}
</style>
