import { core as api } from '@shared/services/api';

export const provisionBillingAccount = async billingAccount => {
  const res = await api.post(`/v1/gcp_billing_accounts`, billingAccount);
  return res.data;
};

export const activateBillingAccount = async billingAccountId => {
  const res = await api.post(`/v1/gcp_billing_accounts/${billingAccountId}/activate`, billingAccountId);
  return res.data;
};

export const listBillingAccounts = async () => {
  const res = await api.get(`/v1/gcp_billing_accounts`);
  return res.data;
};

export const getBillingAccountComputeEngineSettings = async billingAccountId => {
  const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/settings_summary`);
  return res.data;
};

export const getBillingAccountSettings = async (billingAccountId, billingOffering) => {
  const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/settings/${billingOffering}`);
  return res.data;
};

export const getSavingsDashboard = async (billingAccountId, timeframe) => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/savings_dashboard`, {
      params: { timeframe },
    });
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }

    throw e;
  }
};

export const getCloudSQLSavingsDashboard = async (billingAccountId, timeframe) => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/cloud_sql/savings_dashboard`, {
      params: { timeframe },
    });
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }

    throw e;
  }
};

export const getCommitmentsDashboard = async billingAccountId => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/commitments_dashboard`);
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }
    throw e;
  }
};

export const requestCloudSQLSavingsAnalysis = billingAccountId => {
  const url = `/v1/gcp_billing_accounts/${billingAccountId}/cloud_sql_savings_analysis`;
  return api.post(url, {});
};

export const getServiceUsage = async (billingAccountId, timeframe) => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/service_usage`, {
      params: { timeframe },
    });
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }
    throw e;
  }
};
