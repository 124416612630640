import type { ComputeSavingsDashboardResponse } from './savings.models';

import { azure } from '@shared/services/api';
import env from '@shared/utilities/env';

/* eslint-disable camelcase */
export enum AgreementType {
  EnterpriseAgreement = 'EnterpriseAgreement',
  MicrosoftCustomerAgreement = 'MicrosoftCustomerAgreement',
}

export enum BillingScopeStatus {
  Provisioning = 'Provisioning',
  Capturing = 'Capturing',
  ProvioningAdditionalTenants = 'ProvioningAdditionalTenants',
  Active = 'Active',
}

export type CreateBillingScopeRequest = {
  agreement_type?: AgreementType;
  billing_account_id?: string;
  billing_profile_id?: string;
  service_principal_id?: string;
  storage_account_subscription_id?: string;
  storage_account_resource_group_name?: string;
  storage_account_name?: string;
};

export type BillingScopeResponse = {
  id: string;
  agreement_type: AgreementType;
  billing_account_id: string;
  billing_profile_id?: string | undefined;
  service_principal_id: string;
  storage_account_subscription_id: string;
  storage_account_resource_group_name: string;
  storage_account_name: string;
  status: BillingScopeStatus;
  display_name: string;
};

export type BillingScopeGlobalSettings = {
  savings_analysis_completed: boolean;
  commitment_management_enabled: boolean;
  commitment_action_subscription: string,
  base_one_year_target_hourly_on_demand_equivalent: string,
  base_three_year_target_hourly_on_demand_equivalent: string,
  reserved_instance_preference: string,
  compute_shrink_allowance_target: string,
  reserved_instance_one_year_allocation_target: string,
  reserved_instance_three_year_allocation_target: string,
  base_target_auto_increase_percentage: string;
  base_last_updated: string;
  smart_last_updated: string;
};

export type StorageAccountConfiguration = {
  storage_account_resource_group_name: string,
  storage_account_prefix: string
};
/* eslint-enable camelcase */

export type BillingScopeExistsResponse = {
  exists: boolean;
};

export async function getAll() {
  const res = await azure.get<BillingScopeResponse[]>(`/v1/billing_scopes`);
  return res.data;
}

export async function getGlobalSettings(billingScopeId: string) {
  const res = await azure.get<BillingScopeGlobalSettings>(`/v1/billing_scopes/${billingScopeId}/settings/global`);
  return res.data;
}

export async function getCustomStorageAccountConfiguration() {
  const res = await azure.get<StorageAccountConfiguration>(`/v1/billing_scopes/storage_account_configuration`);
  return res.data;
}

export async function create(request: CreateBillingScopeRequest) {
  const res = await azure.post<BillingScopeResponse>(`/v1/billing_scopes`, request);
  return res.data;
}

export async function activate(billingAccountId: string) {
  const res = await azure.post<BillingScopeResponse>(`/v1/billing_scopes/${billingAccountId}/activate`);
  return res.data;
}

export async function exists(billingAccountId?: string, billingProfileId?: string) {
  const res = await azure.get<BillingScopeExistsResponse>(`/v1/billing_scopes/exists`, {
    params: {
      billingAccountId,
      billingProfileId,
    },
  });
  return res.data;
}

export async function savingsDashboard(billingScopeId: string, timeframe?: string): Promise<ComputeSavingsDashboardResponse> {
  const res = await azure.get<ComputeSavingsDashboardResponse>(
    `/v1/billing_scopes/${billingScopeId}/savings_dashboard`,
    {
      params: Object.assign({}, timeframe ? { timeframe } : null),
    }
  );
  return res.data;
}

export function generateExportResourceGroupName(): string {
  return env.isProduction() ? 'prosperops' : `prosperops-dev`;
}

export function generateExportStorageAccountName(prefix?: string): string {
  const randomSuffix = crypto.randomUUID().substring(0, 8);
  return prefix != null ? `${prefix}${randomSuffix}` : `prosperops${randomSuffix}`;
}
